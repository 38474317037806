import axios from "./axios";

/* 登录 */

// 登录
export function login(params) {
  return axios({
    url: "base/admin/user/login",
    method: "post",
    encrypt: false,
    data: {
      f_account: params.f_account,
      f_pass: params.f_pass,
    },
  })
}
// 退出登录
export function logout() {
  return axios({
    url: "base/admin/user/logout",
    method: "post",
    encrypt: false,
  })
}

/* 市场 */

// 获取市场信息
export function queryMarket() {
  return axios({
    url: "market/md/info/list",
    method: "post",
  });
}
// 新增/编辑市场信息
export function saveMarket(params) {
  return axios({
    url: "market/md/info/save",
    method: "post",
    data: {
      f_mdbm: params.f_mdbm,
      f_mdmc: params.f_mdmc,
      f_jd: params.f_jd,
      f_wd: params.f_wd,
      f_jkUrl: params.f_jkUrl,
      f_szm: params.f_szm,
      f_lxrmc: params.f_lxrmc,
      f_lxdh: params.f_lxdh,
      f_thdz: params.f_thdz,
      f_lxbm: params.f_lxbm,
      f_psjl: params.f_psjl,
      f_mxdz: params.f_mxdz,
      f_pssf: params.f_pssf,
      f_psfje: params.f_psfje,
      f_pssj: params.f_pssj,
      f_qsje: params.f_qsje,
      f_jldw: params.f_jldw,
      f_bzl: params.f_bzl,
      f_csbm: params.f_csbm,
      f_city: params.f_city,
      f_glbl: params.f_glbl,
      f_hg: params.f_hg,
      f_mdlx: params.f_mdlx,
      f_prtsj: params.f_prtsj,
      f_prtzt: params.f_prtzt,
      f_sfmr: params.f_sfmr,
      f_storyid: params.f_storyid,
      f_flbm: params.f_flbm,
      f_sfzhm: params.f_sfzhm,
      f_sfzxx_img: params.f_sfzxx_img,
      f_yyzzbm: params.f_yyzzbm,
      f_yyzz_img: params.f_yyzz_img,
      f_video_path: params.f_video_path,
      f_image_path: params.f_image_path,
      f_video_image_type: params.f_video_image_type,
      f_fh_mb_id: params.f_fh_mb_id,
      f_th_mb_id: params.f_th_mb_id,
      f_s_img: params.f_s_img,
      f_s_txt: params.f_s_txt,
      f_yhmc: params.f_yhmc,
    }
  });
}
// 删除市场信息
export function deleteMarket(params) {
  return axios({
    url: "market/md/info/del",
    method: "post",
    data: {
      id: params.id,
    }
  });
}
// 获取单个市场信息
export function queryMarketOne(params) {
  return axios({
    url: "market/md/info/one",
    method: "post",
    data: {
      id: params.id,
    }
  });
}

/* 摊位 */

// 获取摊位信息
export function queryBooth(params) {
  return axios({
    url: "market/twda/list",
    method: "post",
    data: {
      bm: params.bm,
    }
  });
}// 新增/编辑摊位信息
export function saveBooth(params) {
  return axios({
    url: "market/twda/save",
    method: "post",
    data: {
      f_bmbm: params.f_bmbm,
      f_dh: params.f_dh,
      f_dpmc: params.f_dpmc,
      f_ewm: params.f_ewm,
      f_gsdjz: params.f_gsdjz,
      f_gszh: params.f_gszh,
      f_ico: params.f_ico,
      f_id: params.f_id,
      f_img: params.f_img,
      f_jdrq: params.f_jdrq,
      f_jssj: params.f_jssj,
      f_kssj: params.f_kssj,
      f_lxr: params.f_lxr,
      f_px: params.f_px,
      f_splb: params.f_splb,
      f_spzx: params.f_spzx,
      f_swdjz: params.f_swdjz,
      f_swzh: params.f_swzh,
      f_text: params.f_text,
      f_twjs: params.f_twjs,
      f_twlx: params.f_twlx,
      f_tytp: params.f_tytp,
      f_wsqkz: params.f_wsqkz,
      f_wszh: params.f_wszh,
      f_wxh: params.f_wxh,
      f_wz: params.f_wz,
      f_xssz: params.f_xssz,
      f_yysm: params.f_yysm,
      f_yyzt: params.f_yyzt,
      f_zt: params.f_zt,
    }
  });
}
// 删除摊位信息
export function deleteBooth(params) {
  return axios({
    url: "market/twda/del",
    method: "post",
    data: {
      bm: params.bm,
      id: params.id,
    }
  });
}
// 获取单个摊位信息
export function queryBoothOne(params) {
  return axios({
    url: "market/twda/one",
    method: "post",
    data: {
      bm: params.bm,
      twId: params.twId,
    }
  });
}

/* 商品 */

// 获取商品信息
export function queryGoods(params) {
  return axios({
    url: "market/spda/list/by/tw",
    method: "post",
    data: {
      bm: params.bm,
      twid: params.twid,
    }
  });
}
// 新增/编辑商品信息
/* 
f_zt =>
0 -- 下市
1 -- 上市
*/
export function saveGoods(params) {
  return axios({
    body: {
      "TWID_HEAD": params.f_twid,
    },
    url: "market/spda/save",
    method: "post",
    data: {
      f_hddj: params.f_hddj,
      f_imgUrl: params.f_imgUrl,
      f_sftj: params.f_sftj,
      f_spbm: params.f_spbm,
      f_spdj: params.f_spdj,
      f_spjs: params.f_spjs,
      f_spkc: params.f_spkc,
      f_splb: params.f_splb,
      f_splx: params.f_splx,
      f_spmc: params.f_spmc,
      f_sptm: params.f_sptm,
      f_spxx0: params.f_spxx0,
      f_spxx1: params.f_spxx1,
      f_twid: params.f_twid,
      f_xsxh: params.f_xsxh,
      f_zt: params.f_zt,
      f_wlxx: params.f_wlxx,
    }
  });
}
// 删除商品信息
export function deleteGoods(params) {
  return axios({
    body: {
      "TWID_HEAD": params.f_twid,
    },
    url: "market/spda/del",
    method: "post",
    data: {
      twId: params.f_twid,
      spId: params.f_spbm,
    }
  });
}
// 获取商品单独信息
export function queryGoodsOne(params) {
  return axios({
    url: "market/spda/one",
    method: "post",
    data: {
      scbm: params.scbm,
      twId: params.twId,
      spId: params.spId,
    }
  });
}
// 获取商品评价信息
export function queryGoodsEvaluate(params) {
  return axios({
    url: "merchant/sp/rate/list/" + params,
    method: "get",
  });
}

/* 推荐 */

// 查询商品推荐信息
export function queryGoodsRecommend(params) {
  return axios({
    url: "market/dp/tj/sp/list",
    method: "post",
    encrypt: false,
    data: {
      bm: params.bm,
    },
  });
}
// 新增/编辑商品推荐信息
export function saveGoodsRecommend(params) {
  return axios({
    url: "market/dp/tj/sp/save",
    method: "post",
    data: {
      f_bm: params.f_bm,
      f_id: params.f_id,
      f_img_path: params.f_img_path,
      f_sp_id: params.f_sp_id,
      f_sp_name: params.f_sp_name,
      f_twid: params.f_twid,
    }
  });
}
// 删除商品推荐信息
export function deleteGoodsRecommend(params) {
  return axios({
    url: "market/dp/tj/sp/del",
    method: "post",
    data: {
      f_bm: params.f_bm,
      f_id: params.f_id,
    }
  });
}

/* 分类 */

// 获取分类信息
export function queryClass(params) {
  return axios({
    url: "market/spfl/list",
    method: "post",
    data: {
      f_scbm: params.f_scbm,
    },
  });
}
// 新增/编辑分类信息
export function saveClass(params) {
  return axios({
    url: "market/spfl/save",
    method: "post",
    data: {
      f_lxbm: params.f_lxbm,
      f_lxmc: params.f_lxmc,
      f_pbm: params.f_pbm,
      f_scbm: params.f_scbm,
    }
  });
}
// 删除分类信息
export function deleteClass(params) {
  return axios({
    url: "market/spfl/del",
    method: "post",
    data: {
      f_lxbm: params.f_lxbm,
      f_scbm: params.f_scbm,
    }
  });
}

/* 业态 */

// 获取业态信息
export function queryType() {
  return axios({
    url: "market/md/lx/info/list",
    method: "post",
    encrypt: false,
  });
}
// 新增/编辑业态信息
export function saveType(params) {
  return axios({
    url: "market/md/lx/info/save",
    method: "post",
    data: {
      f_lxbm: params.f_lxbm,
      f_lxmc: params.f_lxmc,
    }
  });
}
// 删除业态信息
export function deleteType(params) {
  return axios({
    url: "market/md/lx/info/del",
    method: "post",
    data: {
      f_lxbm: params.f_lxbm,
    }
  });
}

/* 视频 */

// 获取视频信息
export function queryVideo(params) {
  return axios({
    url: "market/video/list",
    method: "post",
    data: {
      f_bm: params.f_bm,
    }
  });
}
// 新增/编辑视频信息
/* 
f_type =>
0 -- 首页视频
1 -- 展示视频
*/
export function saveVideo(params) {
  return axios({
    url: "market/video/save",
    method: "post",
    data: {
      f_bm: params.f_bm,
      f_id: params.f_id,
      f_image_url: params.f_image_url,
      f_sort: params.f_sort,
      f_type: params.f_type,
      f_video_name: params.f_video_name,
      f_video_url: params.f_video_url,
      f_sp_id: params.f_sp_id,
    }
  });
}
// 删除视频信息
export function deleteVideo(params) {
  return axios({
    url: "market/video/del",
    method: "post",
    data: {
      f_bm: params.f_bm,
      f_id: params.f_id,
    }
  });
}

/* 注册 */

// 获取注册信息
export function queryLogon() {
  return axios({
    url: "market/register/md/info/list",
    method: "post",
  });
}
// 新增/编辑注册信息
export function saveLogon(params) {
  return axios({
    url: "market/video/save",
    method: "post",
    data: {
      f_lxbm: params.f_lxbm,
      f_lxmc: params.f_lxmc,
      f_scdz: params.f_scdz,
      f_scmc: params.f_scmc,
      f_sfzhm: params.f_sfzhm,
      f_sfzxx_img: params.f_sfzxx_img,
      f_sjhm: params.f_sjhm,
      f_yhmc: params.f_yhmc,
      f_yyzz_img: params.f_yyzz_img,
      f_yyzzbm: params.f_yyzzbm,
    }
  });
}
// 审核注册信息
export function verifyLogon(params) {
  return axios({
    url: "market/register/md/info/verify",
    method: "post",
    data: {
      f_id: params.f_id,
      f_reson: params.f_reson,
      f_state: params.f_state,
    }
  });
}
// 获取单独注册信息
export function queryLogonOne(params) {
  return axios({
    url: "market/register/md/info/query",
    method: "post",
    data: {
      f_sjhm: params.f_sjhm,
    }
  });
}

/* 地址 */

// 获取地址信息
export function queryAddress() {
  return axios({
    url: "base/provinces/cities/areas",
    method: "get",
    encrypt: false,
  });
}

/* 订单 */

// 获取订单信息
/* 
1.f_state =>
0 -- 待付款
1 -- 已付款
2 -- 待发货
3 -- 待配送
4 -- 待签收
5 -- 待评价
6 -- 已完成
7 -- 已作废
8 -- 已退款
9 -- 待成团
10 -- 退款中
81 -- 退款中
82 - 拒绝退款
83 -- 同意退款，请填写快递单号
84 -- 退货中
85 -- 收到货，货不对板，拒绝退款
86 -- 部分退款
999 -- 所有订单
9997 -- 81,82,83,84,85,86,8,10
9998 -- 1,2,3
2.f_iskj =>
0 -- 商品订单
1 -- 卡券订单
 */
export function queryOrder(params) {
  return axios({
    body: {
      "BM-ID": params.f_bm,
    },
    url: "merchant/sh/order/query/state",
    method: "post",
    data: {
      f_bm: params.f_bm,
      f_iskj: params.f_iskj,
      f_jssj: params.f_jssj,
      f_kssj: params.f_kssj,
      f_o_id: params.f_o_id,
      f_state: params.f_state,
      f_twid: params.f_twid,
    }
  });
}
// 获取单独订单信息
export function queryOrderOne(params) {
  return axios({
    url: "merchant/user/order/one",
    method: "post",
    encrypt: false,
    data: {
      f_o_id: params.f_o_id,
    }
  });
}
// 新增/编辑订单回填信息
export function saveOrderDelivery(params) {
  return axios({
    url: "merchant/sh/order/kdxx/update",
    method: "post",
    data: {
      f_kddh: params.f_kddh,
      f_kdgs: params.f_kdgs,
      f_o_id: params.f_o_id,
    }
  });
}
// 新增/编辑退款信息
/* 
f_verify_type =>
1 -- 同意
2 -- 拒绝
*/
export function saveOrderRefund(params) {
  return axios({
    url: "merchant/sh/order/refund/verify",
    method: "post",
    data: {
      f_o_id: params.f_o_id,
      f_verify_type: params.f_verify_type,
    }
  });
}
// 新增/编辑接收回填信息
/* 
f_receiving_type =>
1 -- 签收无问题，可退款
2 -- 签收货品有问题，无法退款
*/
export function saveOrderReceive(params) {
  return axios({
    url: "merchant/sh/order/refund/receiving",
    method: "post",
    data: {
      f_o_id: params.f_o_id,
      f_receiving_remark: params.f_receiving_remark,
      f_receiving_type: params.f_receiving_type,
    }
  });
}

/* 快递公司 */

// 获取快递公司信息
export function queryCompany() {
  return axios({
    url: "merchant/kdgs/info/list",
    method: "get",
  });
}
// 新增/编辑快递公司信息
export function saveCompany(params) {
  return axios({
    url: "merchant/kdgs/info/save",
    method: "post",
    data: {
      f_id: params.f_id,
      f_name: params.f_name,
      f_sort: params.f_sort,
    }
  });
}
// 删除快递公司信息
export function deleteCompany(params) {
  return axios({
    url: "merchant/kdgs/info/del",
    method: "post",
    data: {
      id: params.id,
    }
  });
}

/* 退货 */

// 获取退款信息
export function queryRefund() {
  return axios({
    url: "merchant/order/refund/reason/list",
    method: "get",
  });
}
// 新增/编辑退款信息
export function saveRefund(params) {
  return axios({
    url: "merchant/order/refund/reason/save",
    method: "post",
    data: {
      f_id: params.f_id,
      f_reason: params.f_reason,
      f_sort: params.f_sort,
    }
  });
}
// 删除退款信息
export function deleteRefund(params) {
  return axios({
    url: "merchant/order/refund/reason/del",
    method: "post",
    data: {
      id: params.id,
    }
  });
}

/* 微信 */

// 微信推送消息
/* 
f_type =>
developer -- 开发版
trial -- 体验版
formal -- 正式版
 */
export function saveWechat(params) {
  return axios({
    url: "merchant/wx/applet/send/notice",
    method: "post",
    data: {
      f_data: params.f_data,
      f_o_id: params.f_o_id,
      f_page: params.f_page,
      f_t_id: params.f_t_id,
      f_type: "formal",
    }
  });
}

/* 版本 */

// 获取版本信息
export function queryVersionOne() {
  return axios({
    url: "market/version/one",
    method: "get",
    encrypt: false,
  });
}
// 新增版本信息
export function saveVersion(params) {
  return axios({
    url: "market/version/save",
    method: "post",
    data: {
      f_d_url: params.f_d_url,
      f_v_code: params.f_v_code,
      f_v_info: params.f_v_info,
      f_v_name: params.f_v_name,
      f_v_u: params.f_v_u,
    },
  });
}

/* 开票 */

// 获取待开票信息
/* 
f_kp_state =>
1 -- 未开票
2 -- 已开票
 */
export function queryInvoice(params) {
  return axios({
    url: "merchant/sh/order/kp/apply/list",
    method: "post",
    data: {
      f_bm: params.f_bm,
    },
  });
}
// 编辑待开票信息
export function saveInvoice(params) {
  return axios({
    url: "merchant/user/order/kp/update/state",
    method: "post",
    data: {
      f_o_id: params.f_o_id,
    },
  });
}

/* 密码 */

// 重置密码
export function queryPassword(params) {
  return axios({
    url: "market/user/password/reset",
    method: "post",
    data: {
      f_id: params.f_id,
    },
  });
}
// 编辑密码
export function savePassword(params) {
  return axios({
    body: {
      "BM-ID": params.f_bm,
    },
    url: "market/user/password/modify",
    method: "post",
    data: {
      newPassword: params.newPassword,
      oldPassword: params.oldPassword,
    },
  });
}

/* 商户 */

// 获取商户信息
export function queryMerchant() {
  return axios({
    url: "market/wx/applet/list",
    method: "get",
  });
}
// 新增/编辑商户信息
export function saveMerchant(params) {
  return axios({
    url: "market/wx/applet/save",
    method: "post",
    data: {
      appId: params.appId,
      appSecret: params.appSecret,
      appkey: params.appkey,
      bmbm: params.bmbm,
      cusid: params.cusid,
      tlAppId: params.tlAppId,
    }
  });
}
// 删除商户信息
export function deleteMerchant(params) {
  return axios({
    url: "market/wx/applet/del/{bmbm}" + params,
    method: "get",
  });
}

/* 充值 */

// 获取充值信息
export function queryRecharge(params) {
  return axios({
    url: "merchant/sh/pz/yh/rule/list",
    method: "post",
    data: {
      bm: params.bm,
    }
  });
}
// 新增/编辑充值信息
export function saveRecharge(params) {
  return axios({
    url: "merchant/sh/pz/yh/rule/save",
    method: "post",
    data: {
      f_bm: params.f_bm,
      f_gmje: params.f_gmje,
      f_id: params.f_id,
      f_name: params.f_name,
      f_zsje: params.f_zsje,
    }
  });
}
// 删除充值信息
export function deleteRecharge(params) {
  return axios({
    url: "merchant/sh/pz/yh/rule/del",
    method: "post",
    data: {
      bm: params.bm,
      id: params.id,
    }
  });
}
// 获取充值用户信息
export function queryRechargeCustom(params) {
  return axios({
    url: "merchant/sh/user/balance",
    method: "post",
    data: {
      f_bm: params.f_bm,
    }
  });
}
// 获取单独充值用户信息
export function queryRechargeCustomOne(params) {
  return axios({
    url: "merchant/sh/user/balance/log/list",
    method: "post",
    data: {
      f_bm: params.f_bm,
      f_hy_id: params.f_hy_id,
    }
  });
}