import axios from "axios"
import router from "@/router";
import { Message  } from 'element-ui';
import { MessageBox } from 'element-ui';

let aes = require("./aes")
let service = ""
let params = ""
let encrypt = ""

//1. 创建新的axios实例
service = axios.create({
    baseURL: "https://leg.njyfkj.cn/api/",
})

// 2.请求拦截器
service.interceptors.request.use(config => {
    config.headers = {
        "Content-Type": "application/json",
        "Infol-Token": localStorage.getItem("token") ? localStorage.getItem("token") : "",
        ...config.body,
    }
    encrypt = (config.encrypt == false) ? config.encrypt : true
    params = config.data ? config.data : ""
    if (encrypt) {
        config.data = config.data ? aes.encrypt(JSON.stringify(config.data)) : ""
    } else {
        config.data = config.data ? config.data : ""
    }
    return config
}, error => {
    Promise.reject(error)
})

// 3.响应拦截器
service.interceptors.response.use(response => {
    if (response.data.code == 0) {
        return Promise.resolve({
            params: params,
            code: response.data.code,
            data: response.data.data ? (encrypt ? aes.decrypt(response.data.data) : response.data.data) : "",
        })
    } else {
        return Promise.resolve({
            params: params,
            code: response.data.code,
            msg: response.data.msg,
        })
    }
}, error => {
    if (error == "Network Error") {
        Message("当前网络异常，请检查您的网络");
    } else {
        if (error.response.data.code) {
            if (error.response.data.msg == "token已经过期，无权限访问") {
                MessageBox("您的账号已在其他地方登录，请确认您的账号安全", "温馨提示", {
                    confirmButtonText: "确定",
                }).then(() => {
                    localStorage.clear()
                    router.push("/login");
                }).catch(() => {
                    localStorage.clear()
                    router.push("/login");
                });
            } else {
                return Promise.resolve({
                    params: params,
                    code: error.response.data.code,
                    msg: error.response.data.msg,
                })
            }
        } else {
            Message("当前服务器异常，请稍后再尝试");
            return Promise.resolve(error.response.data)
        }
    }
})

//4.导入文件
export default service