import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: 'login'
},
{
  path: '/login',
  name: 'login',
  component: () => import('../views/LoginView.vue'),
},
{
  path: '/home',
  name: 'home',
  redirect: '/index',
  component: () => import('../views/HomeView.vue'),
  children: [{
    path: '/index',
    name: 'index',
    component: () => import('../views/index/IndexView.vue'),
    meta: {
      title: '首页',
    },
  },
  {
    path: '/approval',
    name: 'approval',
    component: () => import('../views/approve/ApprovalView.vue'),
    meta: {
      title: '用户审核',
    },
  },
  {
    path: '/password',
    name: 'password',
    component: () => import('../views/password/PasswordView.vue'),
    meta: {
      title: '密码重置',
    },
  },
  {
    path: '/merchant',
    name: 'merchant',
    component: () => import('../views/merchant/MerchantView.vue'),
    meta: {
      title: '信息配置',
    },
  },
  {
    path: '/format',
    name: 'format',
    component: () => import('../views/format/FormatView.vue'),
    meta: {
      title: '市场类型',
    },
  },
  {
    path: '/information',
    name: 'information',
    component: () => import('../views/market/MarketView.vue'),
    meta: {
      title: '市场列表',
    },
  },
  {
    path: '/marketEdit',
    name: 'marketEdit',
    component: () => import('../views/market/EditView.vue'),
    meta: {
      title: '市场编辑',
    },
  },
  {
    path: '/recommend',
    name: 'recommend',
    component: () => import('../views/recommend/RecommendView.vue'),
    meta: {
      title: '启动页设置',
    },
  },
  {
    path: '/page',
    name: 'page',
    component: () => import('../views/page/PageView.vue'),
    meta: {
      title: '首页设置',
    },
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('../views/video/VideoView.vue'),
    meta: {
      title: '视频管理',
    },
  },
  {
    path: '/booth',
    name: 'booth',
    component: () => import('../views/booth/BoothView.vue'),
    meta: {
      title: '摊位列表',
    },
  },
  {
    path: '/goods',
    name: 'goods',
    component: () => import('../views/goods/GoodsView.vue'),
    meta: {
      title: '商品设置',
    },
  },
  {
    path: '/class',
    name: 'class',
    component: () => import('../views/class/ClassView.vue'),
    meta: {
      title: '商品分类设置',
    },
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/company/CompanyView.vue'),
    meta: {
      title: '快递公司设置',
    },
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('../views/recharge/RechargeView.vue'),
    meta: {
      title: '充值信息设置',
    },
  },
  {
    path: '/custom',
    name: 'custom',
    component: () => import('../views/recharge/CustomView.vue'),
    meta: {
      title: '充值用户列表',
    },
  },
  {
    path: '/refund',
    name: 'refund',
    component: () => import('../views/refund/RefundView.vue'),
    meta: {
      title: '退款原因设置',
    },
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/order/OrderView.vue'),
    meta: {
      title: '订单列表',
    },
  },
  {
    path: '/orderDetails',
    name: 'orderDetails',
    component: () => import('../views/order/DetailsView.vue'),
    meta: {
      title: '订单详情',
    },
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: () => import('../views/invoice/InvoiceView.vue'),
    meta: {
      title: '开票列表',
    },
  },
  {
    path: '/edition',
    name: 'edition',
    component: () => import('../views/edition/EditionView.vue'),
    meta: {
      title: '版本管理',
    },
  },
  ],
},
]

const router = new VueRouter({
  routes
})

export default router